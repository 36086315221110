<i18n>
{
  "de": {
    "loading": "Daten werden geladen..."
  }
}
</i18n>

<template>
  <div class="l-main-layout">
    <div sticky-container>
      <LayoutHeader :portfolio="portfolio" :portfolios="portfolios" />

      <div v-if="ready" class="page-header">
        <slot name="header"></slot>
      </div>

      <div class="compass-contents">
        <div v-if="!ready">
          {{ $t('loading') }}
        </div>
        <template v-if="ready">
          <slot></slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import LayoutHeader from '@/components/shared/LayoutHeader.vue'

export default {
  components: {
    LayoutHeader,
  },

  props: {
    portfolio: {
      type: Object,
    },
    ready: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState('portfolio', ['portfolios']),
  },
}
</script>

<style lang="scss" scoped>
.l-main-layout {
  height: 100svh;

  & > * {
    height: 100%;
    overflow-y: auto;
  }

  & .compass-contents {
    padding: var(--spacing-m) var(--spacing-m) var(--spacing-xl);
  }
}
</style>
