<i18n>
{
  "de": {
    "pageTitle": "Renovationsstandards",
    "settings": "Einstellungen",
    "addButton": "Renovationsstandard hinzufügen",
    "nameTitle": "Name",
    "editButton": "Bearbeiten",
    "returnButton": "Zurück",
    "confirmDelete": "Wollen Sie diesen Renovationsstandard wirklich löschen?",
    "noRecords": "In diesem Portfolio sind noch keine Renovationsstandards vorhanden.",
    "defaultDeletionDialog": "Renovationsstandardvorlagen können nicht gelöscht werden",
    "pageDescription": "Erlaubt das definieren der im Portfolio standardmässig verfügbaren Renovationsstandards. Diese können pro Liegenschaft überschrieben werden."
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="renovation-standards-page">
    <template #header>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/settings`">{{ $t('settings') }}</router-link>
        </template>
      </PageTitle>
    </template>

    <template #default>
      <p>{{ $t('pageDescription') }}</p>
      <br />

      <DetailList v-if="portfolioRenovationStandards.length" has-header class="standards-table">
        <template #header>
          <span>{{ $t('nameTitle') }}</span>
          <span></span>
        </template>
        <li
          v-for="(renovation_standard, index) in portfolioRenovationStandards"
          :key="index"
          class="renovation-standard-row"
        >
          <span>{{ renovation_standard.name }}</span>
          <ListButtonWrapper class="align-right">
            <ListEditButton
              v-if="getPortfolioPermission('EDIT_RENOVATION_STANDARDS')"
              @click="onEditModalOpen(renovation_standard)"
            />
            <ListDeleteButton
              v-if="getPortfolioPermission('EDIT_RENOVATION_STANDARDS')"
              :disabled="renovation_standard.default"
              @click="onDelete(renovation_standard)"
            />
          </ListButtonWrapper>
        </li>
      </DetailList>
      <div v-else>
        {{ $t('noRecords') }}
      </div>
      <ButtonWrapper>
        <Button
          v-if="getPortfolioPermission('EDIT_RENOVATION_STANDARDS')"
          icon="plus"
          :text="$t('addButton')"
          @click="onEditModalOpen(null)"
        />
      </ButtonWrapper>

      <EditRenovationStandardModal
        v-if="editModalOpen"
        :portfolio="portfolio"
        :renovation-standard="editStandard"
        @close="onEditModalClose"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import EditRenovationStandardModal from '@/components/settings/renovation_standards/EditRenovationStandardModal.vue'
import PageTitle from '@/components/shared/PageTitle.vue'
import Button from '@/components/cui/inputs/Button.vue'

export default {
  name: 'settingsRenovationStandards',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    ButtonWrapper,
    MainLayout,
    DetailList,
    EditRenovationStandardModal,
    PageTitle,
    Button,
  },

  data() {
    return {
      editStandard: null,
      editModalOpen: false,
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),

    portfolioRenovationStandards() {
      return this.portfolio.renovation_standards.filter((rs) => !rs.building_id)
    },
  },

  methods: {
    ...mapActions({
      deleteRenovationStandard: 'portfolio/deleteRenovationStandard',
    }),

    onEditModalOpen(editStandard) {
      this.editStandard = editStandard
      this.editModalOpen = true
    },

    onEditModalClose(e) {
      this.editModalOpen = false
    },

    async onDelete(renovationStandard) {
      if (!renovationStandard.default) {
        if (confirm(this.$t('confirmDelete'))) {
          try {
            this.error = null
            await this.deleteRenovationStandard(renovationStandard)
            this.$emit('close')
          } catch (error) {
            this.error = error
          }
        }
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.standards-table .align-right {
  text-align: right;
  justify-content: flex-end;
}
</style>
